<template>
  <div class="student-attendance-page pa-2">
    <v-row justify="center">
      <v-col md="6" v-if="attendanceStudent">
      <!-- <v-col md="6" v-if="false"> -->
        <v-card>
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="attendanceStudent.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                attendanceStudent.full_name
              }}</v-list-item-title>
              <v-list-item-subtitle>{{
                attendanceStudent.username
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card class="mt-2">
          <v-card-title class="pt-2 pl-4"
            >
            <v-list-item class="px-0">
              <v-list-item-content>
                <v-list-item-title class="text-h6">{{ attendanceStudent.subject ? `${attendanceStudent.subject.name} |` : '' }}{{ attendanceStudent.subject ? attendanceStudent.subject.room.sections: attendanceStudent.room.sections }}</v-list-item-title>
                <v-list-item-subtitle>{{moment(startDate).format('ll')}} - {{moment(endDate).format('ll')}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            </v-card-title
          >
          
          <v-card-text class="text-center">
            <v-progress-circular
            v-if="attendanceStudent.attended_count != 0"
              :value="attendancePercentage"
              color="primary"
              class="attendance-percentage"
              size="250"
              width="20"
              rotate="180"
            >
              <div class="mt-6">
                <p
                  class="
                    pa-0
                    ma-0
                    percentage-text
                    primary--text
                    font-weight-bold
                  "
                >
                  {{ Math.round(attendancePercentage) }}%
                </p>
                <p class="text-h6 ma-0 mt-5 pa-0">PRESENT</p>
              </div>
            </v-progress-circular>
            <h4 v-else>No lectures were conducted during this period</h4>
          </v-card-text>
        </v-card>
        <v-card class="mt-2 py-1 px-2">
          <v-row justify="center" class="my-4">
            <v-col cols="4" class="text-center">
              <h2>{{ attendanceStudent.total_count }}</h2>
              <p class="ma-0 font-weight-bold">Total</p>
            </v-col>
            <v-divider width vertical></v-divider>
            <v-col cols="4" class="text-center">
              <h2>{{ attendanceStudent.attended_count }}</h2>
              <p class="ma-0 font-weight-bold">Attended</p>
            </v-col>
            <v-divider width vertical></v-divider>
            <v-col cols="4" class="text-center">
              <h2>
                {{
                  attendanceStudent.total_count -
                  attendanceStudent.attended_count
                }}
              </h2>
              <p class="ma-0 font-weight-bold">Skipped</p>
            </v-col>
          </v-row>
        </v-card>
        <h4 class="mx-3 my-3">Attendance History</h4>
        <intract-smart-list
          class="mt-2"
          ref="student-attendance-smart-list"
          :endpoint="studentAttendanceHistoryEndpoint"
          paginated
        >
          <template v-slot:list-item="{ item: attendance }">
            <v-list-item>
              <v-list-item-content >
                <v-list-item-subtitle>
                    <p class="pa-0 ma-0 font-weight-bold">
                      {{ moment(attendance.date).format("ll") }}
                    </p>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    is_daily ? attendance.creator.full_name :
                    attendance.faculty.full_name
                  }}</v-list-item-subtitle>

                  <v-list-item-subtitle class="text-caption"
                    >Recorded on
                    {{
                      moment(is_daily ? attendance.updated : attendance.created).format("lll")
                    }}</v-list-item-subtitle
                  >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon color="primary" v-if="attendance.attendees.includes(attendanceStudent.id)">mdi-check</v-icon>
                <v-icon color="red" v-else>mdi-close</v-icon>

              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

          </template>
        </intract-smart-list>
      </v-col>
      <v-col md="6" v-else>
        <v-skeleton-loader
          class="py-2"
          elevation="1"
          type="list-item-avatar"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="py-2"
          elevation="1"
          type="card"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="py-2"
          elevation="1"
          type="list-item-three-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import moment from 'moment';
import IntractSmartList from "@components/generics/IntractSmartList";

export default {
  name: "StudentAttendance",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractSmartList,
  },
  props: {
    daily: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      moment,
      subjectId: this.$route.params.subjectId,
      studentId: this.$route.params.studentId,
      startDate: this.$route.params.startDate,
      endDate: this.$route.params.endDate,
      attendanceStudent: null,
    };
  },
  computed: {
    attendancePercentage() {
      if (!this.attendanceStudent) return 0;
      return (
        (this.attendanceStudent.attended_count * 100) /
        this.attendanceStudent.total_count
      );
    },

    // appBarOptions() {
      // return this.is_daily && this.currentUser.is_student ? {
      //   iconButton: { 
      //     icon: "mdi-qrcode-scan", 
      //     action: () => {
      //         this.$router.push({
      //             name: "AttendanceQRScanner",
      //         });
      //     }, 
      //   },
      // }: null
    // },

    is_daily(){
      let is_daily = (this.daily || this.$route.name == "StudentAttendanceDaily")
      return is_daily
    },

    studentAttendanceEndpoint() {
      // eslint-disable-next-line
      var url = null
      if(this.is_daily)
        url = `${this.endpoints.classroomAttendanceViewSet}student-stats/`;
      else
        url = `${this.endpoints.attendanceViewSet}student-stats/`;
      url = this.Helper.addUrlParams(url, [
        `student=${this.studentId}`,
        ...this.is_daily ? [] : [`subject=${this.subjectId}`],
        `start_date=${this.startDate}`,
        `end_date=${this.endDate}`,
      ]);
      return url;
    },

    studentAttendanceHistoryEndpoint(){
      var url = null
      if(this.is_daily)
        url = this.Helper.addUrlParams(this.endpoints.classroomAttendanceViewSet,["ordering=-created",`date__gte=${this.startDate}`,`date__lte=${this.endDate}`,`subject=${this.subjectId}`]);
      else
        url = this.Helper.addUrlParams(this.endpoints.attendanceViewSet,["ordering=-created",`date__gte=${this.startDate} 00:00:00`,`date__lte=${this.endDate} 00:00:00`,`subject=${this.subjectId}`]);
      return url
    },
  },
  methods: {
    async getStudentAttendanceDetails() {
      this.attendanceStudent = await this.api.call(
        this.essentials,
        this.studentAttendanceEndpoint
      );
      console.log(this.attendanceStudent);
    },

    async onRefresh() {
      this.getStudentAttendanceDetails();
    },
  },
  created() {
    this.getStudentAttendanceDetails();
  },
};
</script>

<style lang="sass" scoped>
.percentage-text
  font-size: 60px
</style>